import Vue from 'vue'
import Vuex from 'vuex'
import comm from '@/store/comm'
import dialog from '@/store/dialog'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        comm,
        dialog
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        reducer(data) {
            console.log(data);
            return {
                comm: {
                    checked: data.comm.checked
                }
            }
        }
    })]
})
export default store