import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

const originalReplace =VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err);
};

// 页面路由
export default new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            component: () => import("@/views/Home")
        },
        {
            path: '/itInfrastructure',
            component: () => import("@/views/services/it-infrastructure-services")
        },
        {
            path: "/itImplementation",
            component: () => import("@/views/services/it-implementation-support")
        },
        // {
        //     path: "/cloudServices",
        //     component: () => import("@/views/services/cloud-services")
        // },
        /// 新改版自研产品开始
        {
            path: '/selfDeveloped',
            component: () => import("@/views/selfDeveloped/index")
        },
        {
            path: "/enterpriseDoc",
            component: () => import("@/views/selfDeveloped/child/enterprise-doc")
        },
        {
            path: "/multiPlatform",
            component: () => import("@/views/selfDeveloped/child/multi-platform")
        },
        {
            path: "/eBPM",
            component: () => import("@/views/selfDeveloped/child/e-bpm")
        },
        {
            path: '/bornCloud',
            component: () => import("@/views/selfDeveloped/child/born-cloud")
        },
        {
            path: "/adManagement",
            component: () => import("@/views/selfDeveloped/child/ad-management")
        },
        {
            path: "/configurePrice",
            component: () => import("@/views/selfDeveloped/child/configure-price-quote")
        },
        {
            path: "/eTeamer",
            component: () => import("@/views/selfDeveloped/child/e-teamer")
        },
        {
            path: '/cube',
            component: () => import("@/views/selfDeveloped/child/cube-mobile")
        },
        {
            path: "/itsm",
            component: () => import("@/views/selfDeveloped/child/eITSM")
        },
        {
            path: '/officeSuite',
            component: () => import("@/views/selfDeveloped/child/office-suite")
        },
        // 新改版自研产品结束
        // 新版解决方案开始
        {
            path: '/innovativeSolution',
            component: () => import("@/views/innovativeSolution/index")
        },
        {
            path: '/highTech',
            component: () => import("@/views/innovativeSolution/child/high-tech")
        },
        {
            path: '/dataLake',
            component: () => import("@/views/innovativeSolution/child/data-lake")
        },
        {
            path: '/customerEngagement',
            component: () => import("@/views/innovativeSolution/child/customer-engagement")
        },
        {
            path: "/retailMembership",
            component: () => import("@/views/innovativeSolution/child/retail-membership")
        },
        {
            path: '/education',
            component: () => import("@/views/innovativeSolution/child/education-industry")
        },
        {
            path: '/healthCare',
            component: () => import("@/views/innovativeSolution/child/health-care")
        },
        {
            path: '/marketingSolution',
            component: () => import("@/views/innovativeSolution/child/marketing-solution")
        },
        {
            path: '/storeInspection',
            component: () => import("@/views/innovativeSolution/child/store-inspection")
        },
        {
            path: '/sale',
            component: () => import("@/views/innovativeSolution/child/sale-management")
        },
        //新版解决方案结束
        // 集成服务开始
        {
            path: '/integration',
            component: () => import("@/views/integrationService/index")
        },
        {
            path: '/mInfra',
            component: () => import("@/views/integrationService/child/m-infrastructure")
        },
        {
            path: '/add',
            component: () => import("@/views/integrationService/child/value-add")
        },
        {
            path: '/desktop',
            component: () => import("@/views/integrationService/child/desktop-cloud")
        },
        {
            path: '/back',
            component: () => import("@/views/integrationService/child/back-system")
        },
        {
            path: '/hyper',
            component: () => import("@/views/integrationService/child/hyperfusion-solution")
        },
        {
            path: '/wan',
            component: () => import("@/views/integrationService/child/wan-solution")
        },
        {
            path: '/database',
            component: () => import("@/views/integrationService/child/database-solution")
        },
        {
            path: '/dataLakePrevention',
            component: () => import("@/views/integrationService/child/data-lake-prevention")
        },
        {
            path: '/activeDirectory',
            component: () => import("@/views/integrationService/child/active-directory")
        },
        {
            path: '/endpoint',
            component: () => import("@/views/integrationService/child/endpoint-standardization")
        },
        // 集成服务结束
        // 新版云服务开始
        {
            path: '/cloudService',
            component: () => import("@/views/cloudService/index")
        },
        {
            path: '/cloudAdoption',
            component: () => import("@/views/cloudService/child/cloud-adoption")
        },
        {
            path: '/wellArchitected',
            component: () => import("@/views/cloudService/child/well-architected")
        },
        {
            path: '/landing',
            component: () => import("@/views/cloudService/child/landing-zone")
        },
        {
            path: '/cloudNative',
            component: () => import("@/views/cloudService/child/cloud-native")
        },
        {
            path: '/dataPlatform',
            component: () => import("@/views/cloudService/child/data-platform")
        },
        {
            path: '/virtual',
            component: () => import("@/views/cloudService/child/virtual-desktop")
        },
        {
            path: '/hosting',
            component: () => import("@/views/cloudService/child/hosting-operations")
        },
        {
            path: '/migration',
            component: () => import("@/views/cloudService/child/migration")
        },
        {
            path: '/textile',
            component: () => import("@/views/cloudService/child/case/textile-company")
        },
        {
            path: '/software',
            component: () => import("@/views/cloudService/child/case/software-company")
        },
        {
            path: '/planning',
            component: () => import("@/views/cloudService/child/case/planning-company")
        },
        {
            path: '/serviceCatalog',
            component: () => import("@/views/cloudService/child/service-catalog")
        },
        {
            path: '/qualification',
            component: () => import("@/views/cloudService/child/qualification-index")
        },
        // 新版云服务结束
        // AI开始
        {
            path: '/selfDevelopedAI',
            component: () => import("@/views/selfDevelopedAI/index")
        },
        {
            path: '/egpt',
            component: () => import("@/views/selfDevelopedAI/child/e-gpt")
        },
        {
            path: '/gptDoc',
            component: () => import("@/views/selfDevelopedAI/child/gpt-doc")
        },
        {
            path: '/gptKnowledge',
            component: () => import("@/views/selfDevelopedAI/child/gpt-knowledge")
        },
        {
            path: '/gptIntelligent',
            component: () => import("@/views/selfDevelopedAI/child/gpt-intelligent")
        },
        // AI结束
        // {
        //     path: "/selfDeveloped",
        //     component: () => import("@/views/services/self-developed-product")
        // },
        {
            path: "/itTechnical",
            component: () => import("@/views/services/it-technical")
        },
        {
            path: '/directors',
            component: () => import("@/views/investor/directors-management")
        },
        {
            path: '/corporate',
            component: () => import("@/views/investor/corporate-governance")
        },
        {
            path: '/investor',
            component: () => import("@/views/investor/investor-relations")
        },
        {
            path: '/about',
            component: () => import("@/views/about/about-eden")
        },
        {
            path: '/anti',
            component: () => import("@/views/about/eden-anti-corruption")
        },
        {
            path: '/contact',
            component: () => import("@/views/about/contact-eden")
        }

    ]
})