import {get, post} from "@/utils/axios"

// 获取企业治理文件
const corporateApi = p => get("/api/file/getCorporateGovernance", p);

// 获取投资者关系文件
const statementsApi = p => get("/api/file/getFileList", p);

// 申请回呼
const callbackApi = p => post("/api/callBack/syncAddCallBackByEn", p);

export default {
    corporateApi,
    statementsApi,
    callbackApi
}

