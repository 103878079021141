<template>
  <el-row>
    <en-header/>
    <router-view></router-view>
    <en-footer/>
  </el-row>
</template>

<script>
import header from './common/en-header'
import footer from './common/en-footer'
export default {
  name: "my-index",
  components: {
    'en-header': header,
    'en-footer': footer
  }
}
</script>

<style scoped>

</style>