const dialog ={
    state: {
        contactUsDialog: false,
        successDialog: false,
        phoneDialog: false,
        showEmail: true,
    },
    mutations: {
        setContactDialog(state, info) {
            state.contactUsDialog = info;
        },
        setSuccessDialog(state, info) {
            state.successDialog = info;
        },
        setPhoneDialog(state, info) {
            state.phoneDialog = info;
        },
    }
};

export default dialog;