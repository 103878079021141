<template>
  <el-col>
    <my-index/>
    <el-dialog
      :visible.sync="$store.state.dialog.contactUsDialog"
      height="760px"
      width="1200px"
      :destroy-on-close="true"
      custom-class="contact-us dialog"
    >
      <el-col class="header" slot="title">
        <el-col class="logo"><img src="@/static/img/common/logo.png"/></el-col>
      </el-col>
      <el-col class="contact-form">
        <el-col class="form-title">Please fill in the particulars for our customer service personnel to contact you.</el-col>
        <el-form :mode="form" class="form">
          <el-col class="left-form">
            <el-form-item>
              <el-input v-model="form.phone" placeholder="Phone number*"/>
              <el-col class="verify-result" style="margin-top: 10px">{{phoneResult}}</el-col>
            </el-form-item>
            <el-form-item>
              <el-input v-model="form.code" placeholder="SMS verification code*" style="width: 218px;vertical-align: top" @blur="compareCode"/>
              <verify-code class="verify-code" ref="ref_validateCode" @change="changeCode" ></verify-code>
              <el-col class="verify-result">{{verifyResult}}</el-col>
            </el-form-item>
            <el-form-item>
              <el-select placeholder="Product of interest" v-model="form.product" style="width: 380px">
                <el-option v-for="item in productList" :key="item.value" :label="item.label" :value="item.value">{{item.label}}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="form.company" placeholder="Company"/>
            </el-form-item>
            <el-form-item>
              <el-input v-model="form.name" placeholder="Name"/>
            </el-form-item>
          </el-col>
          <el-col class="right-form">
            <el-form-item>
              <el-input v-model="form.email" placeholder="Email"/>
            </el-form-item>
            <el-form-item>
              <el-input v-model="form.position" placeholder="Position"/>
            </el-form-item>
            <el-form-item>
              <el-input type="textarea" v-model="form.demand" placeholder="Requirements description" style="width: 380px;" height="192"/>
            </el-form-item>

          </el-col>
        </el-form>
      </el-col>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSubmit" class="submit-button" :loading="submitLoading">Submit</el-button>
        <el-button @click="handleReset">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :visible.sync="$store.state.dialog.successDialog"
        width="680"
        height="460"
        :destroy-on-close="true"
        custom-class="success dialog"
    >
      <el-col class="header" slot="title">
        <el-col class="logo"><img src="@/static/img/common/logo.png"/></el-col>
      </el-col>
      <el-col class="tip">
        <el-col class="tip-big">
          Message submitted successfully!
        </el-col>
        <el-col class="tip-small">
          Message submitted successfully!
        </el-col>
        <el-col class="back-home">
          <el-col class="content" @click.native="goPage('/home', 'home')">BACK HOME</el-col>
        </el-col>
      </el-col>
    </el-dialog>
    <el-dialog
        :visible.sync="$store.state.dialog.phoneDialog"
        width="680"
        height="460"
        :destroy-on-close="true"
        custom-class="phone dialog"
    >
      <el-col class="header" slot="title">
        <el-col class="logo"><img src="@/static/img/common/logo.png"/></el-col>
      </el-col>
      <el-col class="tip">
<!--        <el-col class="holtine">-->
<!--          Call the hotline for telephone inquiry-->
<!--        </el-col>-->
        <el-col class="phone">Call telephone： 400-830-0095 </el-col>
        <el-col class="service-hour">
          <el-col>Service time（UTC/GMT+08:00）: 9:00-12:00 13:00-18:00  Monday-Friday  </el-col>
<!--          <el-col>If the line is busy, please choose online inquiry.</el-col>-->
        </el-col>
        <el-col class="email">
          Email：Dianad@cloudtimesus.com
        </el-col>
      </el-col>
    </el-dialog>
  </el-col>
</template>

<script>
import index from './views/my-index.vue'
import verifyCode from './components/vue-verify-code'
import api from './api/api'
export default {
  name: 'App',
  components: {
    'my-index': index,
    'verifyCode': verifyCode
  },
  data() {
    return {
      form: {
        phone: '',
        name: '',
        code: '',
        product: '',
        company: '',
        demand: '',
        email: '',
        position: ''
      },
      verifyCode: '',
      verifyResult: '',
      productList: [
        {label: 'Microsoft Dynamics 365', value: 'Microsoft Dynamics 365'},
        {label: 'Microsoft 365', value: 'Microsoft 365'},
        {label: 'Microsoft Azure', value: 'Microsoft Azure'},
        {label: 'Microsoft Surface', value: 'Microsoft Surface'},
        {label: 'Citrix', value: 'Citrix'},
        {label: 'Vmware', value: 'Vmware'},
        {label: '天空卫士', value: '天空卫士'},
        {label: 'Veritas', value: 'Veritas'},
        {label: 'Adobe', value: 'Adobe'},
        {label: 'coreldeaw', value: 'coreldeaw'},
        {label: 'PTC', value: 'PTC'},
        {label: '福昕', value: '福昕'},
        {label: 'Uipath', value: 'Uipath'},
        {label: 'Navicat', value: 'Navicat'},
        {label: 'Jetbrains', value: 'Jetbrains'},
        {label: 'Acunetix', value: 'Acunetix'},
        {label: '金山', value: '金山'},
        {label: 'NoMachine', value: 'NoMachine'},
        {label: '西门子Mendix', value: '西门子Mendix'},
        {label: 'CRM', value: 'CRM'},
        {label: '云文档管理系统', value: '云文档管理系统'},
        {label: 'eTeamer', value: 'eTeamer'},
        {label: '云资产管理系统', value: '云资产管理系统'},
        {label: '多云管理平台', value: '多云管理平台'},
        {label: '邮件大附件管理', value: '邮件大附件管理'},
        {label: 'AD域管理平台', value: 'AD域管理平台'},
        {label: 'IT基础架构服务', value: 'IT基础架构服务'},
        {label: '上云服务', value: '上云服务'},
        {label: '信息安全服务', value: '信息安全服务'},
        {label: '虚拟化服务', value: '虚拟化服务'},
        {label: '其他', value: '其他'}
      ],
      submitLoading: false,
      phoneResult: ""
    }
  },
  mounted() {
  },
  methods: {

    changeCode(code) {
      this.verifyCode = code;
    },
    compareCode() {
      if(this.form.code.toUpperCase() != this.verifyCode) {
        this.verifyResult = "验证码输入错误，请重新输入";
        this.form.code = "";
        this.$refs["ref_validateCode"].draw();
        return false;
      }
      this.verifyResult = "";
      return true;
    },
    handleSubmit() {
      let obj = this;
      if(obj.form.phone == "") {
        obj.phoneResult = "手机号码不能为空";
        return;
      }
      if(obj.form.code == "") {
        obj.verifyResult = "验证码不能为空";
        return;
      }
      obj.submitLoading = true;
      api.callbackApi({
        phone: obj.form.phone,
        code: obj.form.code,
        company: obj.form.company,
        name: obj.form.name,
        demand: obj.form.demand,
        product: obj.form.product,
        email: obj.form.email,
        positoin: obj.form.position
      }).then(res => {
        if(res.code == 200) {
          obj.$message.success("提交成功，等待客服联系");
          obj.submitLoading = false;
          obj.$store.commit("setContactDialog", false);
          obj.verifyResult = "";
          obj.form = {
            phone: '',
            name: '',
            code: '',
            product: '',
            company: '',
            demand: '',
            email: '',
            position: ''
          }
          obj.$store.commit("setSuccessDialog", true);
          return;
        }
      })
    },
    handleReset() {
      this.$store.commit("setContactDialog", false);
      this.form = {
        phone: '',
        name: '',
        code: '',
        product: '',
        company: '',
        demand: '',
        email: '',
        position: ''
      };
      this.verifyResult = "";
    },
    goPage(pagePath, checked) {
      this.$store.commit("setChecked", checked);
      this.$router.push(pagePath);
      this.$store.commit("setSuccessDialog", false);
    }
  }
}
</script>

<style lang="less">

  * {
    font-family: Helvetica;
    //font-size: 0;
  }

  // banner正文字体大小
  .banner-body {
    font-size: 24px;
  }

  // 功能介绍正文字体大小
  .introduce-body {
    font-size: 18px;
  }

  // banner标题字体大小
  .banner-title {
    font-size: 40px;
  }

  // 副标题字体大小
  .sub-title {
    font-size: 34px;
  }

  // 功能介绍标题字体大小
  .introduce-title {
    font-size: 24px;
  }

  // banner正文、功能介绍正文字体
  .banner-body,.introduce-body {
    font-family: Helvetica;
  }

  // banner标题、副标题、功能介绍标题字体
  .banner-title,.sub-title,.introduce-title {
    font-family: "ibm regular";
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
    font-size: 0;
  }

  img {
    object-fit: contain;
  }

  body {
    margin: 0;
    background-color: #FFFFFF;
  }

  .el-dialog__header {
    padding: 0 0 8px 0 !important;
  }

  .dialog {
    .header {
      padding-bottom: 9px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      .logo {
        margin-left: 21px;
      }
    }


    .el-dialog__footer {
      text-align: center;
    }

    .el-button--primary:focus, .el-button--primary:hover {
      background-color: #D7000F;
      border-color: unset;

    }
    .el-button--primary {
      width: 148px;
      height: 42px;
      background: #D7000F;
      border-radius: 21px 21px 21px 21px;
      opacity: 1;
      border-color: unset;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
    }

    .el-button {
      padding: 5px 20px;
    }

    .el-button--default:focus, .el-button--default:hover {
      background-color: #11487B;
      border-color: unset;

    }

    .el-button--default {
      width: 148px;
      height: 42px;
      background: #11487B;
      border-radius: 21px 21px 21px 21px;
      opacity: 1;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
    }

  }

  .contact-us {
    .el-dialog__body {
      height: 600px;
      padding: 0;
    }

    .header {
      padding-bottom: 9px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      .logo {
        margin-left: 21px;
      }
    }
    .contact-form {
      padding: 80px 0 0 136px;
      .form-title {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-bottom: 16px;
      }

      .left-form {
        width: 380px;

        .verify-code {

          margin-left: 16px;
        }
        .verify-result {
          color: #D7000F;
          font-size: 10px;
          line-height: 1px !important;
        }
      }

      .right-form {
        width: 380px;
        margin-left: 32px;
        .el-textarea {
          width: 380px;
          textarea {
            height: 192px;
          }
        }

      }

      .form {
        margin-top: 16px;
      }



    }
  }
  .submit-button {
    margin-right: 40px;
  }

  .success {
    width: 680px !important;
    .el-dialog__body {
      height: 400px;
      padding: 0;
    }
    .tip {
      padding: 102px 121px;
      .tip-big {
        font-size: 28px;
        font-weight: 400;
        color: #D7000F;
        line-height: 32px;
        text-align: center;
      }
      .tip-small {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        text-align: center;
        margin-top: 16px;
      }
      .back-home {
        padding-left: 140px;
        margin-top: 31px;
        .content:hover {
          cursor: pointer;
        }
        .content {
          width: 148px;
          height: 42px;
          background: #D7000F;
          border-radius: 21px 21px 21px 21px;
          opacity: 1;
          padding: 7px 25px;
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 28px;
        }
      }
    }
  }
  .phone {
    width: 680px !important;

    .el-dialog__body {
      height: 400px;
      padding: 0;
    }

    .tip {
      display: flex;
      flex-direction: column;
      padding: 90px 30px 0;
      //.holtine {
      //  font-size: 16px;
      //  font-weight: 400;
      //  color: #333333;
      //  line-height: 28px;
      //  text-align: center;
      //}

      .phone {
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
        margin-top: 16px;
        color: #787878;
      }

      .service-hour {
        font-size: 18px;
        font-weight: 400;
        color: #787878;
        line-height: 28px;
        text-align: left;
        margin-top: 16px;
      }
      .email {
        font-size: 18px;
        font-weight: 400;
        color: #787878;
        text-align: left;
        margin-top: 16px;
      }
    }
  }
</style>
