import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui';


const service = axios.create({
    baseURL: "/official-api",
    timeout: 5000,
    withCredentials: true,
    headers: {
        contentType: "application/json;charset=UTF-8"
    },
});


service.interceptors.response.use(response => {
    // console.log(response)
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    if (response.status === 200) {
        return response.data;
    } else {
        // console.log(response,"response");
        Promise.reject();
    }
}, error => {
    console.log(error.response,"error")
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
            case 400:
                Message.error('错误请求')
                break;
            case 401:
                Message.error('未授权')
                break;
            case 403:
                Message.error('拒绝访问');
                break;
            case 404:
                Message.error('请求错误,未找到该资源')
                //window.location.href = "/NotFound"
                break;
            case 405:
                Message.error('请求方法未允许')
                break;
            case 408:
                Message.error('请求超时')
                break;
            case 500:
                Message.error('服务器端出错')
                break;
            case 501:
                Message.error('网络未实现')
                break;
            case 502:
                Message.error('网络错误')
                break;
            case 503:
                Message.error('服务不可用')
                break;
            case 504:
                Message.error('网络超时')
                break;
            case 505:
                Message.error('http版本不支持该请求')
                break;
            default:
                Message.error(`连接错误${error.response.status}`)
        }
    } else {
        // 超时处理
        if (qs.stringify(error).includes('timeout')) {
            Message.error('服务器响应超时，请刷新当前页')
        }
        Message.error('连接服务器失败')
    }
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
});

// 请求拦截 添加token
service.interceptors.request.use(
    config => {
        // let time = new Date().getTime()
        // let logintoken = localStorage.getItem('accessToken')
        // //为请求头对象，添加token验证的Authorization字段
        // config.headers.Authorization = logintoken
        // console.log(config);
        // let configUrl = config.url.substring(config.url.indexOf("/"), config.url.length)
        // console.log(configUrl);
        // config.headers.Authorization1 = md5(secretKey+time+configUrl)
        // config.headers.Time = time
        // console.log(config)
        return config
    }
    , function (error) {
        // Do something with request error
        return Promise.reject(error)
    })

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params){
    console.log(url);
    return new Promise((resolve, reject) =>{
        service.get(url, {
            params: params
        }).then(res => {
            resolve(res);
        }).catch(err =>{
            reject(err)
        })
    });}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        service.post(url,params)
            .then(res => {
                resolve(res);
            })
            .catch(err =>{
                reject(err)
            })
    });
}

export default service;