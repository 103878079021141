<template>
  <el-row>
    <el-col class="footer" :span="24">
<!--      <el-col class="official-info">-->
<!--        <el-col class="footer-content">-->
<!--          <el-col class="footer-product-services" :span="6">-->
<!--            <el-col class="services-list" :span="24">-->
<!--              <el-col class="title" :span="24">-->
<!--                Product & Service-->
<!--              </el-col>-->
<!--              <el-col class="item item-first" :span="24" @click.native="goPage('/integration','productServices')">-->
<!--                Integration Service-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/cloudService', 'productServices')">-->
<!--                Cloud Service-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/innovativeSolution', 'productServices')">-->
<!--                Innovation Solution-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/selfDeveloped', 'productServices')">-->
<!--                Self-developed Product-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/selfDevelopedAI', 'productServices')">-->
<!--                Self-developed AI Innovative Products-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/itTechnical', 'productServices')">-->
<!--                Training and Certification-->
<!--              </el-col>-->
<!--            </el-col>-->
<!--          </el-col>-->
<!--          <el-col class="footer-Investors" :span="5">-->
<!--            <el-col class="investors-list" :span="24">-->
<!--              <el-col class="title" :span="24">-->
<!--                Investors-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/investor', 'investors')">-->
<!--                Investor Relations-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/corporate', 'investors')">-->
<!--                Corporate Governance-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/directors', 'investors')">-->
<!--                Board of Directors and Management-->
<!--              </el-col>-->
<!--            </el-col>-->
<!--          </el-col>-->
<!--          <el-col class="footer-about-us" :span="2">-->
<!--            <el-col class="about-us-list" :span="24">-->
<!--              <el-col class="title" :span="24">-->
<!--                About Us-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/about', 'about')">-->
<!--                Company Profile-->
<!--              </el-col>-->
<!--              <el-col class="item" :span="24" @click.native="goPage('/contact', 'about')">-->
<!--                Contact Us-->
<!--              </el-col>-->
<!--            </el-col>-->
<!--          </el-col>-->
<!--          <el-col class="footer-qrcode" :span="6">-->
<!--            <el-col class="wechat-qrcode" :span="10">-->
<!--              <el-col class="qrcode" :span="24"></el-col>-->
<!--              <el-col class="qrcode-title" :span="24">WeChat Official Account</el-col>-->
<!--            </el-col>-->
<!--            <el-col class="weibo-qrcode" :span="11">-->
<!--              <el-col class="qrcode" :span="24"></el-col>-->
<!--              <el-col class="qrcode-title" :span="24">Weibo</el-col>-->
<!--            </el-col>-->
<!--            <el-col class="contact" :span="24">-->
<!--              Find out more about us at hotline：400 830 0095-->
<!--            </el-col>-->
<!--          </el-col>-->
<!--        </el-col>-->
<!--      </el-col>-->
      <el-col class="footer-all-right">
        Cloudtimes is a company based on California. Contact info is info@cloudtimesus.com
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "en-footer",
  methods: {
    goPage(pagePath, checked) {
      this.$store.commit("setChecked", checked);
      this.$router.push(pagePath)
    }
  }
}
</script>

<style lang="less" scoped>

  @media screen and (max-width: 1300px) and (min-width: 1200px){
    .footer{
      width: 100%;
    }
  }

  @media screen and (max-width: 1200px){
    .footer {
      background-size: 1200px;
      width: 1200px !important;
      .official-info {
        width: 910px !important;
      }
    }

  }
  .footer {
    height: auto;
    background: url("../../static/img/common/footer.png");
    opacity: 1;

    .item:hover {
      cursor: pointer;
    }

    .official-info {
      height: 325px;
      padding-top: 42px;
      margin: auto;
      float: unset;
      width: 1200px;
      .footer-content {

        .footer-Investors {
          margin-left: 25px;
        }

        .footer-about-us {
          margin-left: 10px;
        }

        .footer-qrcode {
          margin-left: 35px;
          width: 300px;

          .contact {
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 20px;
          }
        }

        .qrcode-title {
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          margin-top: 12px;
          text-align: center;
          width: 114px;
        }

        .wechat-qrcode {
          width: 114px;
          .qrcode {
            background: url("../../static/img/common/wechat-qrcode.png") no-repeat top center;
            height: 114px;
            width: 114px;
          }

        }
        .weibo-qrcode {
          width: 114px;
          margin-left: 20px;
          .qrcode {
            background: url("../../static/img/common/weibo-qrcode.png") no-repeat top center;
            height: 114px;
            width: 114px;
          }
        }
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 24px;
      }

      .item-first {
        margin-top: 30px;
      }

      .item {
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        line-height: 24px;
        margin-top: 16px;
      }
    }

    .footer-all-right {
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      height: auto;
      line-height: 30px;
      border-top: 1px solid rgba(255,255,255,0.3);

      .business-a {
        color: #FFFFFF;
        text-decoration: none;
      }
    }


  }
</style>