import Vue from 'vue'
import App from './App.vue'
import elementUI from 'element-ui'
import router from './router/route'
import 'element-ui/lib/theme-chalk/index.css'
import store from '@/store/store'
import BaiduMap from 'vue-baidu-map'
import '@/static/font/font.css'

Vue.use(elementUI)
Vue.use(BaiduMap, {
  ak: 'B8ABejRyixFxnGRZIvz2jNGvpY8a16fV'
})
Vue.config.productionTip = false

router.afterEach(() => {
  window.scrollTo(0,0);
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
