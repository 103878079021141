const comm ={
    state: {
        checked: 'home'
    },
    mutations: {
        setChecked(state, info) {
            state.checked = info;
        }
    }
};

export default comm;