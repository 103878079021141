<template>
  <el-row>
    <el-col class="header-body">
      <el-col class="header">
<!--        <el-col class="header-logo" @click.native="goPage('/home', 'home')">-->
<!--          <img src="@/static/img/common/logo.png">-->
<!--        </el-col>-->
        <el-col class="header-menu-list" >
          <el-col class="item" :class="{active: this.$store.state.comm.checked=='home'}"  @click.native="goPage('/home', 'home')">
            Home
          </el-col>
          <el-col class="item product-services"  :class="{active: this.$store.state.comm.checked=='productServices'}">
            <template>
              <el-menu class="el-menu-demo" mode="horizontal">
                <el-submenu index="1">
                  <template slot="title">Product & Service</template>
                  <el-menu-item index="1-1" @click.native="goPage( '/integration', 'productServices')">Integration Service</el-menu-item>
                  <el-menu-item index="1-2" @click.native="goPage('/cloudService', 'productServices')"> Cloud Service</el-menu-item>
                  <el-menu-item index="1-3" @click.native="goPage('/innovativeSolution', 'productServices')">Innovation Solution</el-menu-item>
                  <el-menu-item index="1-4" @click.native="goPage('/selfDeveloped', 'productServices')">Self-developed product</el-menu-item>
                  <el-menu-item index="1-5" @click.native="goPage('/selfDevelopedAI', 'productServices')">Self-developed AI Innovative Products</el-menu-item>
                </el-submenu>
              </el-menu>
            </template>
          </el-col>
<!--          <el-col class="item" @click.native="cloudMall">-->
<!--            Eden Mall-->
<!--          </el-col>-->
<!--          <el-col class="item investors"  :class="{active: this.$store.state.comm.checked=='investors'}">-->
<!--            <template>-->
<!--              <el-menu class="el-menu-demo" mode="horizontal">-->
<!--                <el-submenu index="1">-->
<!--                  <template slot="title">Investors</template>-->
<!--                  <el-menu-item index="1-1" @click.native="goPage( '/investor', 'investors')">Investor Relations</el-menu-item>-->
<!--                  <el-menu-item index="1-2" @click.native="goPage('/corporate', 'investors')">Corporate Governance</el-menu-item>-->
<!--                  <el-menu-item index="1-3" @click.native="goPage('/directors', 'investors')">Board of Directors and Management</el-menu-item>-->
<!--                </el-submenu>-->
<!--              </el-menu>-->
<!--            </template>-->

<!--          </el-col>-->
<!--          <el-col class="item about-us"  :class="{active: this.$store.state.comm.checked=='about'}">-->
<!--            <template>-->
<!--              <el-menu class="el-menu-demo" mode="horizontal">-->
<!--                <el-submenu index="1">-->
<!--                  <template slot="title">About us</template>-->
<!--                  <el-menu-item index="1-1" @click.native="goPage( '/about', 'about')">About Us</el-menu-item>-->
<!--                  <el-menu-item index="1-2" @click.native="goPage('/contact', 'about')">Contact Us</el-menu-item>-->
<!--                </el-submenu>-->
<!--              </el-menu>-->
<!--            </template>-->
<!--          </el-col>-->
<!--          <el-col class="language">-->
<!--            <el-col class="zh-cn">-->
<!--              <el-link href="/">-->
<!--                中文-->
<!--              </el-link>-->
<!--            </el-col>-->
<!--            <el-col class="line"></el-col>-->
<!--            <el-col class="en">-->
<!--              <el-link href="/en">-->
<!--                EN-->
<!--              </el-link>-->
<!--            </el-col>-->
<!--          </el-col>-->
        </el-col>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "en-header",
  mounted() {
  },
  methods: {
    goPage(pagePath, checked) {
      this.$store.commit("setChecked", checked);
      this.$router.push(pagePath);
    },
    cloudMall() {
      window.open("https://mall.edensoft.com.cn", "_blank")
    }
  }
}
</script>

<style lang="less" scoped>

  @media screen and (max-width: 1300px) and (min-width: 1200px){
    .header-body {
      .header {
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 1200px){
    .header-body {
      .header {
        padding: 0 34px;
        width: 1200px !important;
        margin: auto;
        float: unset;
      }
    }
  }

  .header-body {
    background: #FFF;
    .header {
      height: 70px;
      width: 1300px;
      opacity: 1;
      margin: auto;
      float: unset;
      background: #FFF;

      .header-logo:hover {
        cursor: pointer;
      }

      .header-logo {
        width: auto;
        margin-top: 14px;
        img {
          width: 150px;
          height: 40px;
        }
      }
      .header-menu-list {
        padding-right: 48px;
        height: 100%;
        float: right;
        width: auto;
        .item:hover {
          cursor: pointer;
        }

        .item:first-child {
          margin-left: 0;
        }
        .cloud-mall {
          margin-left: 40px;
        }
        .item {
          line-height: 70px;
          font-size: 16px;
          margin-left: 40px;
          width: auto;
          color: #414141;

          /deep/ .el-menu--horizontal{
            border-bottom: none;
          }

          .el-submenu /deep/.el-submenu__title {
            font-size: 16px;
            color: #414141;
            line-height: 70px;
            padding: 0;
          }

          /deep/ .el-submenu__icon-arrow {
            display: none;
          }

          .el-menu--horizontal>.el-submenu.is-active /deep/ .el-submenu__title {
            border-bottom: none;
          }

          /deep/ .is-active /deep/ .el-submenu__title {
            border-bottom: none;
            color: #D7000F;
          }

          .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu /deep/ .el-submenu__title {
            color: #555555;
          }

          .el-menu--horizontal /deep/ .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal /deep/ .el-menu-item:not(.is-disabled):hover {
            color: #D7000F !important;
          }
        }
        .active {
          color: #D7000F;
          font-weight: 400;

          .el-submenu /deep/ .el-submenu__title {
            color: #D7000F !important;
            font-weight: 400;
          }
        }
        .language {
          line-height: 70px;
          margin-left: 40px;
          color: #414141;
          width: auto;

          .zh-cn {
            width: auto;
            margin-right: 10px;
            opacity: .4;
          }

          /deep/ .el-link.is-underline:hover:after {
            border-bottom: none;
          }

          /deep/ .el-link.el-link--default:hover {
            color: #D7000F;
            opacity: 1;
          }

          /deep/ .el-link--inner {
            font-size: 16px;
            font-weight: 400;
            color: #414141;
          }
        }

        .line {
          width: 1px;
          height: 16px;
          border: 1px solid #979797;
          margin: 28px 3px;
        }
        .en {
          text-align: right;
          margin-left: 10px;
          width: auto;

          /deep/ .el-link--inner {
            color: #414141;
            font-size: 18px;
          }
        }
        .zh-cn:hover,.en:hover {
          cursor: pointer;
        }
      }
    }
  }

  /deep/ .el-menu /deep/ .el-menu--horizontal {
    border-bottom: none;
  }

</style>